















































import Vue from 'vue'
import SpLineChartSummary from './SpLineChartSummary.vue'
import PieChartSummary from './PieChartSummary.vue'
import ExchangeSummaryTable from './ExchangeSummaryTable.vue'
import TokensTable from './TokensTable.vue'
import ExchangeService from '@/services/exchange'
import { ExchangeWalletPortfolioRes } from '@/services/exchange/models'

export enum TOKEN {
  EUR = 'EUR',
  USD = 'USD'
}

export default Vue.extend({
  components: {
    SpLineChartSummary,
    PieChartSummary,
    ExchangeSummaryTable,
    TokensTable
  },

  props: {
    dashboard: {
      type: Boolean,
      default: true
    }
  },

  data(): {
    tokenFilter: TOKEN
    searchTokenKey: string
    dataExchanges: ExchangeWalletPortfolioRes[]
    isLoading: boolean
    refresh: boolean
  } {
    return {
      tokenFilter: TOKEN.USD,
      searchTokenKey: '',
      dataExchanges: [],
      isLoading: false,
      refresh: false
    }
  },

  computed: {
    TOKEN() {
      return TOKEN
    }
  },

  methods: {
    async getExchangeWalletPortfolio() {
      this.isLoading = true

      try {
        const { data: dataRes } = await ExchangeService.getWalletPortfolio()
        this.dataExchanges = dataRes?.data || []
      } finally {
        this.isLoading = false
      }
    },

    clickRefresh() {
      this.getExchangeWalletPortfolio()
      this.refresh = !this.refresh
    }
  },

  created() {
    this.getExchangeWalletPortfolio()
  }
})
