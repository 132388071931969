
































import Vue from 'vue'
import { ExchangeWalletPortfolio, ExchangeWalletPortfolioRes } from '@/services/exchange/models'

type TableRecord = Partial<ExchangeWalletPortfolio> & {
  total: number
}

export default Vue.extend({
  props: {
    dataExchanges: {
      type: Array as () => ExchangeWalletPortfolioRes[],
      required: true
    },

    loading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    tableData(): TableRecord[] {
      return this.dataExchanges.map((item) => ({
        total:
          item.metadata.currency === 'USD'
            ? Number(item.metadata.total.balance_usd)
            : Number(item.metadata.total.balance_eur),
        ccy: item.metadata.currency,
        change_24h: 1
      }))
    }
  }
})
